@import 'variables';
@import 'mixins';

.no-results-message {
  color: var(--rs-color--grey--500);
  text-align: center;
  padding: 50px;
}

.status-info {
  min-width: 500px !important;
  text-align: justify !important;
}
